.navbar{
  display: flex;
  position: fixed;
  top: 0;
}
.navbar-custom {
  color: #ffffff;
  background-color: #6750A4;
  width: 100%;
  height: 10vh;
  left: 0px;
  top: 0px;
}
/* change the brand color*/
.navbar-custom .navbar-brand
{
  color: #FFF7FA;

  font-family: 'Times';
  font-style: normal;
  font-weight: 700;
  font-size: 4vh;
  line-height: 100%;
  top:0;
}
/* change the text color */
.navbar-custom .navbar-text,
.navbar-custom .navbar-nav .nav-link 
{
  display: flex;
  color: #FFF7FA;
  margin-left: 10px;
  margin-right: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 3vh;
  line-height: 100%;
    /*
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    */
}
/*change the color of hovered brand*/
.navbar-custom .navbar-brand:hover
{
  color: #22005D;
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active,
.navbar-custom .nav-item:hover,
.navbar-custom .nav-link.active,
.navbar-custom .nav-link:hover{
    color: #22005D;
}

.collapse.show {
  display: block;
  position: fixed;
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6750A4;
  z-index: 999;
}

.navbar-toggler {
  height: 8vh !important;
  margin-top: 0; /* adjust this value as needed */
}

@media (max-width: 767px) {
  .navbar-collapse {
    position: fixed;
    top: 10vh;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6750A4;
    z-index: 999;
  }
  .navbar-custom .navbar-text,
  .navbar-custom .navbar-nav .nav-link 
  {
    display: flex;
    color: #FFF7FA;
    margin-left: 10px;
    margin-right: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 6vh;
    line-height: 100%;
      /*
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      */
  }
  .navbar-custom .navbar-brand
  {
    color: #FFF7FA;
    font-family: 'Times';
    font-style: normal;
    font-weight: 700;
    font-size: 6vh;
    line-height: 100%;
    margin-top: 0; /* adjust this value as needed */
  }
}
