.footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    align-items: stretch;
    background-color: #6750A4;
    width: 100%
}

.footer-links{
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-links a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 15px;

    color: #FFF7FA;
    text-decoration: none;
    padding: 2vw;
}

.footer-links a:hover{
    color: #22005D;
}

.footer-contact{
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-contact a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 15px;

    color: #FFF7FA;
    text-decoration: none;
    padding: 2vw;
}

.footer-contact a:hover{
    color: #22005D;
}

.follow{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 15px;

    color: #FFF7FA;
    text-decoration: none;
    padding: 2vw;
}

.follow:hover{
    color: #FFF7FA !important;
}

.footer-image img{
    height: 10vh;
}

@media only screen and (orientation: portrait) {
    .footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-around;
        align-items: stretch;
        background-color: #6750A4;
        width: 100%
    }
    
    .footer-links{
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .footer-links a{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 2vw;
        line-height: 15px;
    
        color: #FFF7FA;
        text-decoration: none;
        padding: 1.5vw;
    }
    
    .footer-links a:hover{
        color: #22005D;
    }
    
    .footer-image img{
        height: 0vh;
    }

    .footer-contact{
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .footer-contact a{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5vw;
        line-height: 15px;
    
        color: #FFF7FA;
        text-decoration: none;
        padding: 1.5vw;
    }

    .footer-contact a:hover{
        color: #22005D;
    }

    .footer-contact p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5vw;
        line-height: 15px;
    
        color: #FFF7FA;
        text-decoration: none;
        padding: 1.5vw;
    }

    .footer-contact img{
        width: 0vw;
    }
}