.page {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #fff7fa;
  color: #22005d;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
}

@media screen and (min-width: 600px) {
  .send-email {
    display: flex;
    flex-direction: column;
  }
}

.send-email {
  display: flex;
  flex-direction: row;
}

.label-text {
  text-align: left;
  padding: 0.25rem 0;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: large;
}
.email {
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 10px;
  color: #22005d;
  font-weight: bold;
  border: 1px solid #22005d;
}
.submit {
  margin: 0.5rem 0;
  padding: 1rem;
  border: 1px solid #22005d;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  color: #22005d;
  font-weight: bold;
  margin-left: 10px;
}

.submit:hover {
  background-color: #22005d;
  color: white;
}
