.background {
  background-color: #fff7fa;
  top: 10vh;
}
.container {
  display: flex;
  flex-direction: column;
  color: #22005d;
  font-weight: bold;
  margin: auto;
}

.title {
  margin-top: 100px;
  font-weight: bold;
  font-size: 60px;
}

.text {
  font-size: 20px;
}

progress {
  /* RESETS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 8px;
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: #e7e0eb;
  color: #6750a4;
}

/* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
progress::-webkit-progress-bar {
  background-color: #e7e0eb;
  border-radius: 20px;
}

progress::-webkit-progress-value {
  background-color: #6750a4;
  border-radius: 20px;
}

/* MOZILLA FIREFOX */
progress::-moz-progress-bar {
  background-color: #6750a4;
}
@media screen and (min-width: 1000px) {
  .content {
    flex-direction: column;
    width: 60%;
    /* to override container layout*/
    align-self: center;
    /* border: 1px solid tomato; */
  }
}
.content {
  flex-direction: column;

  /* to override container layout*/
  align-self: center;
  /* border: 1px solid tomato; */
}

.progression-buttons {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: row;
}

input {
  padding: 1rem;
  border-radius: 10px;
  color: #22005d;
  font-weight: bold;
  border: 1px solid #22005d;
}

input:where([type="checkbox"], [type="radio"]) {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  margin: calc(0.75em - 11px) 0.25rem 0 0;
  vertical-align: top;
  border: 2px solid #ddd;
  border-radius: 4px;
  background: #fff no-repeat center center;
}

input[type="radio"] {
  border-radius: 50%;
}

input:where([type="checkbox"], [type="radio"]):where(
    :active:not(:disabled),
    :focus
  ) {
  border-color: #22005d;
  outline: none;
}

input:where([type="checkbox"], [type="radio"]):disabled {
  background: #eee;
}

input[type="checkbox"]:checked {
  background-image: url("checkbox.svg");
}

input[type="radio"]:checked {
  background-image: url("radio.svg");
}
