.container {
  position: relative;
  background-color: white;
}

.textbox {
  width: 100%;
  height: 65px;
  padding-top: 20px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #22005d;
  line-height: none !important;
  margin: none !important;
}

.title {
  top: 0;
  left: 7px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
}
