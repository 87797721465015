.outer-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #fff7fa;
  color: #22005d;
  font-weight: bold;
}

.auth-form-container,
.login-form {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
}

@media screen and (min-width: 800px) and (min-height: 900px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid #22005d;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

.label-signin {
  text-align: left;
  padding: 0.25rem 0;
}

.email,
.password {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  color: #22005d;
  font-weight: bold;
  border: 1px solid #22005d;
}

.submit,
.link-btn {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #22005d;
  font-weight: bold;
}
.submit {
  border: 1px solid #22005d;
  flex-shrink: 2;
}
.link-btn {
  background: none;

  text-decoration: underline;
}

.submit:hover {
  background-color: #22005d;
  color: white;
}
.link-btn:hover {
  color: #6750a4;
}
