body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar{
  display: flex;
  position: fixed;
  top: 0;
}
.navbar-custom {
  color: #ffffff;
  background-color: #6750A4;
  width: 100%;
  height: 10vh;
  left: 0px;
  top: 0px;
}
/* change the brand color*/
.navbar-custom .navbar-brand
{
  color: #FFF7FA;

  font-family: 'Times';
  font-style: normal;
  font-weight: 700;
  font-size: 4vh;
  line-height: 100%;
  top:0;
}
/* change the text color */
.navbar-custom .navbar-text,
.navbar-custom .navbar-nav .nav-link 
{
  display: flex;
  color: #FFF7FA;
  margin-left: 10px;
  margin-right: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 3vh;
  line-height: 100%;
    /*
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    */
}
/*change the color of hovered brand*/
.navbar-custom .navbar-brand:hover
{
  color: #22005D;
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active,
.navbar-custom .nav-item:hover,
.navbar-custom .nav-link.active,
.navbar-custom .nav-link:hover{
    color: #22005D;
}

.collapse.show {
  display: block;
  position: fixed;
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6750A4;
  z-index: 999;
}

.navbar-toggler {
  height: 8vh !important;
  margin-top: 0; /* adjust this value as needed */
}

@media (max-width: 767px) {
  .navbar-collapse {
    position: fixed;
    top: 10vh;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6750A4;
    z-index: 999;
  }
  .navbar-custom .navbar-text,
  .navbar-custom .navbar-nav .nav-link 
  {
    display: flex;
    color: #FFF7FA;
    margin-left: 10px;
    margin-right: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 6vh;
    line-height: 100%;
      /*
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      */
  }
  .navbar-custom .navbar-brand
  {
    color: #FFF7FA;
    font-family: 'Times';
    font-style: normal;
    font-weight: 700;
    font-size: 6vh;
    line-height: 100%;
    margin-top: 0; /* adjust this value as needed */
  }
}

.footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    align-items: stretch;
    background-color: #6750A4;
    width: 100%
}

.footer-links{
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-links a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 15px;

    color: #FFF7FA;
    text-decoration: none;
    padding: 2vw;
}

.footer-links a:hover{
    color: #22005D;
}

.footer-contact{
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-contact a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 15px;

    color: #FFF7FA;
    text-decoration: none;
    padding: 2vw;
}

.footer-contact a:hover{
    color: #22005D;
}

.follow{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 15px;

    color: #FFF7FA;
    text-decoration: none;
    padding: 2vw;
}

.follow:hover{
    color: #FFF7FA !important;
}

.footer-image img{
    height: 10vh;
}

@media only screen and (orientation: portrait) {
    .footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-around;
        align-items: stretch;
        background-color: #6750A4;
        width: 100%
    }
    
    .footer-links{
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .footer-links a{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 2vw;
        line-height: 15px;
    
        color: #FFF7FA;
        text-decoration: none;
        padding: 1.5vw;
    }
    
    .footer-links a:hover{
        color: #22005D;
    }
    
    .footer-image img{
        height: 0vh;
    }

    .footer-contact{
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .footer-contact a{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5vw;
        line-height: 15px;
    
        color: #FFF7FA;
        text-decoration: none;
        padding: 1.5vw;
    }

    .footer-contact a:hover{
        color: #22005D;
    }

    .footer-contact p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5vw;
        line-height: 15px;
    
        color: #FFF7FA;
        text-decoration: none;
        padding: 1.5vw;
    }

    .footer-contact img{
        width: 0vw;
    }
}
.welcome-body
{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: stretch;


 width: 100%;
 height: 100%;


 border-radius: 4px;
 background: #FFF7FA;
 /*background: linear-gradient(160deg, #FFF7FA 76%, #6750A4 77%);*/

 padding-bottom: 10vh;
}


.bwmdn-box {
 border-radius: 4px;
 display: flex;
}


.bwmdn-box img {
 width: 100%;
 height: 100%;
 object-fit: contain;
 border-radius: 4px;
 box-shadow: inset 0px -10px 8px #22005D;
}


.bwmdn-box p {
 position: absolute;
 width: 40vw;
 height: 1vi;
 left: 3vw !important;
 top: 15vi;


 font-family: 'Lora';
 font-style: normal;
 font-weight: 700;
 font-size: 5vi;
 line-height: 5vi;
 /* or 86% */


 color: #22005D;
}


.bwmdn-box a{
 /* padding: 14px 24px;
 border: 0 none;
 font-weight: 700;
 letter-spacing: 1px;
 text-transform: uppercase; */
 border-radius: 0px;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 12px 16px;
 grid-gap: 8px;
 gap: 8px;
 border: none;


 position: absolute;
 width: 30vw;
 height: 8vh;
 left: 8vw;
 top: 26vi;


 background-color: #6750A4!important;


 /* typography/body */


 font-family: 'Inter'!important;
 font-style: normal;
 font-weight: 400;
 font-size: 2vi;
 line-height: 100%;
 /* identical to box height, or 16px */


 display: flex;
 align-items: center;


 color: #F0FFFF!important;




 /* Inside auto layout */


 flex: none;
 order: 1;
 flex-grow: 0;
}


.intro-box {
 border-radius: 4px;
 display: flex;
 flex-direction: column;
 align-items: center;
}


.intro-box h1 {
 padding-top: 4vh;
 font-family: 'Lora';
 font-style: normal;
 font-weight: 700;
 font-size: 36px;
 line-height: 46px;
 text-align: center;

 color: #22005D;
}

.intro-box p {
 width: 40%;

 font-family: 'Verdana';
 font-style: normal;
 font-weight: 400;
 font-size: 14px;
 line-height: 17px;
 text-align: center;

 color: #22005D;
}

.events-box {
 border-radius: 4px;
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 padding: 2vw;
}

.events-box div{
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw;
}

.events-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
    padding: 1vw;
}

.events-box h1 {
    padding-top: 1vh;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
   
    color: #22005D;
}

.membership-box {
 border-radius: 4px;
 display: flex;
 flex-direction: row;
 align-items: flext-start;
 justify-content: center;
}


.membership-text {
 width: 45%;
 padding: 3vw;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.membership-text h1{
 font-family: 'Lora';
 font-style: normal;
 font-weight: 700;
 font-size: 36px;
 line-height: 46px;
 text-align: center;
 /* identical to box height */

 color: #22005D;
 padding-bottom: 3vw;
}

.membership-text p{
 font-family: 'Inter'!important;
 font-style: normal;
 font-weight: 400;
 font-size: 24px;
 line-height: 100%;
 /* or 24px */

 color: #22005D;
 padding-bottom: 3vw;
}

.membership-box img {
 width: 45%;
 height: 45%;
 object-fit: contain;
 border-radius: 4px;
 padding: 3vw;
}

.btn.btn-search
{
 border-radius: 0px;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 10px 45px;
 grid-gap: 10px;
 gap: 10px;


 width:90%;


 background: #6750A4!important;
 border: none;


 font-family: 'Inter'!important;
 font-style: normal;
 font-weight: 700;
 font-size: 24px;
 line-height: 29px;
 /* identical to box height */
 color: #F0FFFF!important;
 /* Inside auto layout */
 flex: none;
 order: 0;
 flex-grow: 0;
}




/* For screens smaller than 600px */
@media only screen and (orientation: portrait) {
    .welcome-body
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;


        width: 100%;
        height: 100%;


        border-radius: 4px;
        background: #FFF7FA;
        background: linear-gradient(160deg, #FFF7FA 90%, #6750A4 77%);
    }
    /* Adjust font sizes and padding for smaller screens */
    .bwmdn-box p {
      width: 35vw;
      font-size: 5vw;
      line-height: 5vw;
      left: 7vw !important;
      top: 30vw;
    }
    
    .bwmdn-box a {
      width: 35vw;
      height: 2vh;
      left: 7vw;
      top: 40vw;
      font-size: 3vw;
    }

    .intro-box h1 {
      font-size: 8vw;
      line-height: 10vw;
      padding-top: 8vh;
    }
    
    .intro-box p {
      width: 80%;
      font-size: 4vw;
      line-height: 5vw;
    }
    
    .events-box h1 {
      font-size: 8vw;
      line-height: 10vw;
      padding-top: 8vh;
    }
    
    .membership-text h1 {
      font-size: 8vw;
      line-height: 10vw;
      padding-bottom: 6vw;
    }
    
    .membership-text p {
      font-size: 5vw;
      line-height: 6vw;
      padding-bottom: 6vw;
    }
    
    .btn.btn-search {
      padding: 5vw 20vw;
      font-size: 6vw;
      line-height: 7vw;
    }
  
    /* Stack events in a column */
    .events-box {
      flex-direction: column;
    }
    
    .events-box div {
      padding: 5vw;
      margin-bottom: 1vw;
    }
    
    .events-box img {
      width: 80%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  
    .membership-box {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: flext-start;
        justify-content: center;
    }
       
    .membership-text {
        width:100%;
        padding: 3vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .membership-text h1{
        font-family: 'Lora';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        /* identical to box height */
        
        color: #22005D;
        padding-bottom: 3vw;
        text-align: center;
    }
    /* Adjust image sizes in membership box */
    .membership-box img {
      width: 80%;
      height: auto;
      padding: 5vw;
      align-self: center;
    }

    .btn.btn-search
    {
        border-radius: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 45px;
        grid-gap: 10px;
        gap: 10px;

        width:90%;
        height:5vh;

        background: #6750A4!important;
        border: none;

        font-family: 'Inter'!important;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */
        color: #F0FFFF!important;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
  }
  
.welcome-body
{
  padding-top: 10vh;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: stretch;


 width: 100%;
 height: 100%;


 border-radius: 4px;
 background: #FFF7FA;
 /*background: linear-gradient(160deg, #FFF7FA 76%, #6750A4 77%);*/

 padding-bottom: 10vh;
}


.bwmdn-box {
 border-radius: 4px;
 display: flex;
}


.bwmdn-box img {
 width: 100%;
 height: 100%;
 object-fit: contain;
 border-radius: 4px;
 box-shadow: inset 0px -10px 8px #22005D;
}


.bwmdn-box p {
 position: absolute;
 width: 40vw;
 height: 1vi;
 left: 6vw;
 top: 15vi;


 font-family: 'Lora';
 font-style: normal;
 font-weight: 700;
 font-size: 5vi;
 line-height: 5vi;
 /* or 86% */


 color: #22005D;

 text-align: center;
}


.bwmdn-box a{
 /* padding: 14px 24px;
 border: 0 none;
 font-weight: 700;
 letter-spacing: 1px;
 text-transform: uppercase; */
 border-radius: 0px;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 12px 16px;
 grid-gap: 8px;
 gap: 8px;
 border: none;


 position: absolute;
 width: 30vw;
 height: 8vh;
 left: 8vw;
 top: 26vi;


 background-color: #6750A4!important;


 /* typography/body */


 font-family: 'Inter'!important;
 font-style: normal;
 font-weight: 400;
 font-size: 2vi;
 line-height: 100%;
 /* identical to box height, or 16px */


 display: flex;
 align-items: center;


 color: #F0FFFF!important;




 /* Inside auto layout */


 flex: none;
 order: 1;
 flex-grow: 0;
}


.intro-box {
 border-radius: 4px;
 display: flex;
 flex-direction: column;
 align-items: center;
}


.intro-box h1 {
 padding-top: 4vh;
 font-family: 'Lora';
 font-style: normal;
 font-weight: 700;
 font-size: 36px;
 line-height: 46px;
 text-align: center;

 color: #22005D;
}

.intro-box p {
 width: 70%;

 font-family: 'Verdana';
 font-style: normal;
 font-weight: 400;
 font-size: 14px;
 line-height: 17px;
 text-align: center;

 color: #22005D;
}

.events-box {
 border-radius: 4px;
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 padding: 2vw;
}

.events-box div{
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw;
}

.events-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
    padding: 1vw;
}

.events-box h1 {
    padding-top: 1vh;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
   
    color: #22005D;
}

.membership-box {
 border-radius: 4px;
 display: flex;
 flex-direction: row;
 align-items: flext-start;
 justify-content: center;
}


.membership-text {
 width: 45%;
 padding: 3vw;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.membership-text h1{
 font-family: 'Lora';
 font-style: normal;
 font-weight: 700;
 font-size: 36px;
 line-height: 46px;
 text-align: center;
 /* identical to box height */

 color: #22005D;
 padding-bottom: 2vw;
}

.membership-text p{
 font-family: 'Inter'!important;
 font-style: normal;
 font-weight: 400;
 font-size: 24px;
 line-height: 100%;
 /* or 24px */

 color: #22005D;
 padding-bottom: 1vw;
}

.membership-box img {
 width: 45%;
 height: 45%;
 object-fit: contain;
 border-radius: 4px;
 padding: 3vw;
}

.btn.btn-search
{
 border-radius: 0px;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 10px 45px;
 grid-gap: 10px;
 gap: 10px;


 width:90%;


 background: #6750A4!important;
 border: none;


 font-family: 'Inter'!important;
 font-style: normal;
 font-weight: 700;
 font-size: 24px;
 line-height: 29px;
 /* identical to box height */
 color: #F0FFFF!important;
 /* Inside auto layout */
 flex: none;
 order: 0;
 flex-grow: 0;

 margin-bottom: 20px;
}




/* For screens smaller than 600px */
@media only screen and (orientation: portrait) {
    .welcome-body
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;


        width: 100%;
        height: 100%;


        border-radius: 4px;
        background: #FFF7FA;
    }
    /* Adjust font sizes and padding for smaller screens */
    .bwmdn-box p {
      width: 35vw;
      font-size: 5vw;
      line-height: 5vw;
      left: 9vw;
      top: 30vw;
    }
    
    .bwmdn-box a {
      width: 35vw;
      height: 2vh;
      left: 7vw;
      top: 40vw;
      font-size: 3vw;
    }

    .intro-box h1 {
      font-size: 8vw;
      line-height: 10vw;
      padding-top: 8vh;
    }
    
    .intro-box p {
      width: 80%;
      font-size: 4vw;
      line-height: 5vw;
    }
    
    .events-box h1 {
      font-size: 8vw;
      line-height: 10vw;
      padding-top: 8vh;
    }
    
    .membership-text h1 {
      font-size: 8vw;
      line-height: 10vw;
      padding-bottom: 6vw;
    }
    
    .membership-text p {
      font-size: 5vw;
      line-height: 6vw;
      padding-bottom: 6vw;
    }
    
    .btn.btn-search {
      padding: 5vw 20vw;
      font-size: 6vw;
      line-height: 7vw;
      margin-bottom: 20px;
    }
  
    /* Stack events in a column */
    .events-box {
      flex-direction: column;
    }
    
    .events-box div {
      padding: 5vw;
      margin-bottom: 1vw;
    }
    
    .events-box img {
      width: 80%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  
    .membership-box {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: flext-start;
        justify-content: center;
    }
       
    .membership-text {
        width:100%;
        padding: 3vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .membership-text h1{
        font-family: 'Lora';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        /* identical to box height */
        
        color: #22005D;
        padding-bottom: 3vw;
        text-align: center;
    }
    /* Adjust image sizes in membership box */
    .membership-box img {
      width: 80%;
      height: auto;
      padding: 5vw;
      align-self: center;
    }

    .btn.btn-search
    {
        border-radius: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 45px;
        grid-gap: 10px;
        gap: 10px;

        width:90%;
        height:5vh;

        background: #6750A4!important;
        border: none;

        font-family: 'Inter'!important;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */
        color: #F0FFFF!important;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

        margin-bottom: 5vh;
    }
  }
  

.construction-body
{
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;


 width: 100%;
 height: 100%;


 border-radius: 4px;
 background: rgba(253,253,253,255);


 padding-top: 10vh;
}

.construction-body img 
{
    width: 50%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
}
.construction-body p 
{
    width: 50%;
    height: 100%;
    color: #6750A4;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 5vi;
    line-height: 60px;
    }
.signin_outer-container__tVTxp {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #fff7fa;
  color: #22005d;
  font-weight: bold;
}

.signin_auth-form-container__CcvoF,
.signin_login-form__-b9F8 {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
}

@media screen and (min-width: 800px) and (min-height: 900px) {
  .signin_auth-form-container__CcvoF {
    padding: 5rem;
    border: 1px solid #22005d;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

.signin_label-signin__Kr4HY {
  text-align: left;
  padding: 0.25rem 0;
}

.signin_email__3p89K,
.signin_password__28OG2 {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  color: #22005d;
  font-weight: bold;
  border: 1px solid #22005d;
}

.signin_submit__1YQXg,
.signin_link-btn__Vr5kX {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #22005d;
  font-weight: bold;
}
.signin_submit__1YQXg {
  border: 1px solid #22005d;
  flex-shrink: 2;
}
.signin_link-btn__Vr5kX {
  background: none;

  text-decoration: underline;
}

.signin_submit__1YQXg:hover {
  background-color: #22005d;
  color: white;
}
.signin_link-btn__Vr5kX:hover {
  color: #6750a4;
}

.select_container__3kVgC {
  position: relative;
  width: 100%;
  border: 1px solid #22005d;
  border-radius: 10px;
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  background-color: white;
  color: #22005d;
  font-weight: bold;
}

.select_container__3kVgC:focus {
  border-color: hs1(200, 100%, 50%);
}

.select_value__3qGkW {
  flex-grow: 1;
}

.select_default__2g1c3 {
  color: #8e8e8e;
  flex-grow: 1;
}

.select_caret-open__gKBjY {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-size: 40px;
  position: absolute;
  font-weight: 100;
  top: -20px;
  right: 9px;
}

.select_caret-close__2f7z7 {
  font-size: 40px;
  position: absolute;
  font-weight: 100;
  top: -10px;
  right: 9px;
}

.select_options__Ybw7b {
  position: absolute;
  margin: 0;
  padding: 0;
  display: none;
  max-height: 15em;
  overflow-y: auto !important;
  width: 100%;
  left: 0;
  top: calc(100% + 0.25em);
  z-index: 100;
  color: #22005d;
  font-weight: bold;
}

.select_options__Ybw7b.select_show__K1Flm {
  display: list-item;
}

.select_option__ZRgtp {
  padding: 0.25em 0.5em;
  cursor: pointer;
  list-style-type: none;
  list-style-position: outside;
  font-weight: 300;

  color: #22005d;
}

.select_option__ZRgtp.select_selected__30fDV {
  color: #22005d;
  font-weight: bold;
  background-color: #e7e0eb;
  margin-bottom: 10px;
  box-shadow: -2em 0 #e7e0eb;
}

.select_option__ZRgtp.select_highlighted__1E16Z {
  background-color: #e7e0eb;
  color: black;
  margin-bottom: 10px;
  box-shadow: -2em 0 #e7e0eb;
}

.select_title__2lfRB {
  top: 0;
  left: 7px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
}

input {
  border: 1px solid black;
}

.hide {
  display: none;
}

.textbox_container__3v2th {
  position: relative;
  background-color: white;
}

.textbox_textbox__2ug2b {
  width: 100%;
  height: 65px;
  padding-top: 20px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #22005d;
  line-height: none !important;
  margin: none !important;
}

.textbox_title__2PEyR {
  top: 0;
  left: 7px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
}

.background {
  background-color: #fff7fa;
  top: 10vh;
}
.container {
  display: flex;
  flex-direction: column;
  color: #22005d;
  font-weight: bold;
  margin: auto;
}

.title {
  margin-top: 100px;
  font-weight: bold;
  font-size: 60px;
}

.text {
  font-size: 20px;
}

progress {
  /* RESETS */
  -webkit-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 8px;
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: #e7e0eb;
  color: #6750a4;
}

/* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
progress::-webkit-progress-bar {
  background-color: #e7e0eb;
  border-radius: 20px;
}

progress::-webkit-progress-value {
  background-color: #6750a4;
  border-radius: 20px;
}

/* MOZILLA FIREFOX */
progress::-moz-progress-bar {
  background-color: #6750a4;
}
@media screen and (min-width: 1000px) {
  .content {
    flex-direction: column;
    width: 60%;
    /* to override container layout*/
    align-self: center;
    /* border: 1px solid tomato; */
  }
}
.content {
  flex-direction: column;

  /* to override container layout*/
  align-self: center;
  /* border: 1px solid tomato; */
}

.progression-buttons {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: row;
}

input {
  padding: 1rem;
  border-radius: 10px;
  color: #22005d;
  font-weight: bold;
  border: 1px solid #22005d;
}

input:where([type="checkbox"], [type="radio"]) {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  margin: calc(0.75em - 11px) 0.25rem 0 0;
  vertical-align: top;
  border: 2px solid #ddd;
  border-radius: 4px;
  background: #fff no-repeat center center;
}

input[type="radio"] {
  border-radius: 50%;
}

input:where([type="checkbox"], [type="radio"]):where(
    :active:not(:disabled),
    :focus
  ) {
  border-color: #22005d;
  outline: none;
}

input:where([type="checkbox"], [type="radio"]):disabled {
  background: #eee;
}

input[type="checkbox"]:checked {
  background-image: url(/static/media/checkbox.4629be5d.svg);
}

input[type="radio"]:checked {
  background-image: url(/static/media/radio.c9fa5725.svg);
}

.next-button {
  width: 90px;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  color: #FFF7FA;
  background-color: #6750A4;
  flex: auto;
  margin-right: 30px;
}

.back-button {
  width: 90px;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #6750A4;
  color: #6750A4;
  background-color: #FFF7FA;
  flex: auto;
  margin-right: 30px;
}

body {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 100vh;
    background: #FFF7FA;
    padding-bottom: 10vh;
}

.directory-body
{
    padding-top: 12vh;
    display: flex;
    flex-direction: row;
    padding-left: 2vw;
    padding-right: 2vw;
}

.filters
{
    width: 25%;
    padding-right: 1vw;
}

.form-control
{
    color: #6750A4;
    background-color: #E7E0EB;
}

.Card
{
    background-color: #E7E0EB;
}

.filter-label
{
    color: #6750A4;
    font-weight: bold;
}

.switch
{
    display: flex;
    justify-content: space-between;
    padding-top: 1vw;
    padding-bottom: 1vw;
    border-bottom: 1px solid #E7E0EB;
}

.plus-button
{
    border-radius: 50%;
    background-color: none;
    color: #6750A4;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.members
{
    width: 75%;
}
.mem-card
{
    width: 30%;
    background-color: #E7E0EB;
    padding-top: 2px;

}

.pagination .page-link {
    background-color: #E7E0EB;
    border-color: #E7E0EB;
    color: #6750A4;
}

.pagination .page-link:hover {
    background-color: #E7E0EB;
    border-color: #22005D;
    color: #22005D;
  }

.pagination .page-item.active .page-link {
    background-color: #6750A4;
    border-color: #6750A4;
    color: #E7E0EB;
  }

@media only screen and (orientation: portrait) {
    .mem-card{
        width: 70%;
        background-color: #E7E0EB;
        padding-top: 2px;
    }
    .form-group.switch {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      
    .form-group.switch label {
    margin-bottom: 10px;
    }

}
.forgotpassword_page__AKkPq {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #fff7fa;
  color: #22005d;
  font-weight: bold;
}

.forgotpassword_container__ejHU_ {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
}

@media screen and (min-width: 600px) {
  .forgotpassword_send-email__1s8Yo {
    display: flex;
    flex-direction: column;
  }
}

.forgotpassword_send-email__1s8Yo {
  display: flex;
  flex-direction: row;
}

.forgotpassword_label-text__1xgB7 {
  text-align: left;
  padding: 0.25rem 0;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: large;
}
.forgotpassword_email__2WNL- {
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 10px;
  color: #22005d;
  font-weight: bold;
  border: 1px solid #22005d;
}
.forgotpassword_submit__1HSS1 {
  margin: 0.5rem 0;
  padding: 1rem;
  border: 1px solid #22005d;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  color: #22005d;
  font-weight: bold;
  margin-left: 10px;
}

.forgotpassword_submit__1HSS1:hover {
  background-color: #22005d;
  color: white;
}

